<template>
    <div class="payTemplateDetail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="120px" size="small">
                <el-form-item label="名称">
                    <el-input v-model="form.name" style="width: 200px" readonly="readonly" />
                </el-form-item>
                <el-form-item label="标志">
                    <el-input v-model="form.flag" style="width: 200px" readonly="readonly" />
                </el-form-item>
                <el-form-item label="可支付(收银)">
                    <el-switch v-model="form.canPay" disabled />
                </el-form-item>
                <el-form-item label="可退款(收银)">
                    <el-switch v-model="form.canRechargeRefund" disabled />
                </el-form-item>
                <el-form-item label="可抹零(收银)">
                    <el-switch v-model="form.canFloor" disabled />
                </el-form-item>
                <el-form-item label="可组合支付(收银)">
                    <el-switch v-model="form.canCombinationPay" disabled />
                </el-form-item>
                <el-form-item label="可充值(会员)">
                    <el-switch v-model="form.canRecharge" disabled />
                </el-form-item>
                <el-form-item label="可退款(会员)">
                    <el-switch v-model="form.canRefund" disabled />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'PayTemplateDetail',
    props: ['form'],
    data() {
        return {};
    },
    methods: {},
};
</script>
